<template>
  <div>
    <div class="">
      <div class="box box-primary">
        <div class="box-header with-border content">
          <h2>Competence</h2>
        </div>
        <div v-if="$store.getters.isPremium || $store.getters.hasNumber" class="box-body">
          <form class="form-inline text-center" style="overflow-x: scroll">
            <label>{{ $store.state.user.locale === 'en_US' ? 'Math' : 'Maths' }} Game</label>
            <b-field style="justify-content: center; margin-bottom: 10px;">
              <b-radio-button v-model="numberGameType" native-value="numberBonds" @input="resetList">
                <span style="font-weight:normal;">Number Bonds</span>
              </b-radio-button>
              <b-radio-button v-model="numberGameType" native-value="timesTables" @input="resetList">
                <span style="font-weight:normal;">Times Tables</span>
              </b-radio-button>
              <b-radio-button v-model="numberGameType" native-value="powerTen" @input="resetList">
                <span style="font-weight:normal;">Powers of 10</span>
              </b-radio-button>
              <b-radio-button v-model="numberGameType" native-value="addSubtract" @input="resetList">
                <span style="font-weight:normal;">Add &amp; Subtract</span>
              </b-radio-button>
              <b-radio-button v-model="numberGameType" native-value="more" @input="resetList">
                <span style="font-weight:normal;">More</span>
              </b-radio-button>
            </b-field>
            <div v-if="numberGameType == 'numberBonds'">
              <select v-model="list" class="form-control" @change="didChange">
                <option value="" selected disabled>
                  -- Choose a game --
                </option>
                <option value="numberBonds_10_add">
                  Bonds to 10 - Add
                </option>
                <option value="numberBonds_10_subtract">
                  Bonds to 10 - Subtract
                </option>
                <option value="numberBonds_10_mixed">
                  Bonds to 10 - Mixed
                </option>
                <option value="numberBonds_20_add">
                  Bonds to 20 - Add
                </option>
                <option value="numberBonds_20_subtract">
                  Bonds to 20 - Subtract
                </option>
                <option value="numberBonds_20_mixed">
                  Bonds to 20 - Mixed
                </option>
                <option value="numberBonds_100_add">
                  Bonds to 100 - Add
                </option>
                <option value="numberBonds_100_subtract">
                  Bonds to 100 - Subtract
                </option>
                <option value="numberBonds_100_mixed">
                  Bonds to 100 - Mixed
                </option>
              </select>
            </div>
            <div v-if="numberGameType == 'timesTables'">
              <select v-model="list" class="form-control" @change="didChange">
                <option value="" selected disabled>
                  -- Choose a game --
                </option>
                <optgroup label="Mixed">
                  <option value="timesTables_2,3,4,5,6,7,8,9,10,11,12_times">
                    2 - 12s, Multiply
                  </option>
                  <option value="timesTables_2,3,4,5,6,7,8,9,10,11,12_divide">
                    2 - 12s, Divide
                  </option>
                  <option value="timesTables_2,3,4,5,6,7,8,9,10,11,12_mixed">
                    2 - 12s, Mixed
                  </option>
                  <option value="timesTables_2,5,10_times">
                    2s, 5s & 10s, Multiply
                  </option>
                  <option value="timesTables_2,5,10_divide">
                    2s, 5s & 10s, Divide
                  </option>
                  <option value="timesTables_2,5,10_mixed">
                    2s, 5s & 10s, Mixed
                  </option>
                  <option value="timesTables_3,4,8_times">
                    3s, 4s & 8s, Multiply
                  </option>
                  <option value="timesTables_3,4,8_divide">
                    3s, 4s & 8s, Divide
                  </option>
                  <option value="timesTables_3,4,8_mixed">
                    3s, 4s & 8s, Mixed
                  </option>
                  <option value="timesTables_2,3,4,5,8,10_times">
                    2s, 3s, 4s, 5s, 8s & 10s, Multiply
                  </option>
                  <option value="timesTables_2,3,4,5,8,10_divide">
                    2s, 3s, 4s, 5s, 8s & 10s, Divide
                  </option>
                  <option value="timesTables_2,3,4,5,8,10_mixed">
                    2s, 3s, 4s, 5s, 8s & 10s, Mixed
                  </option>
                  <option value="timesTables_3,6,9_times">
                    3s, 6s & 9s, Multiply
                  </option>
                  <option value="timesTables_3,6,9_divide">
                    3s, 6s & 9s, Divide
                  </option>
                  <option value="timesTables_3,6,9_mixed">
                    3s, 6s & 9s, Mixed
                  </option>
                  <option value="timesTables_4,8,12_times">
                    4s, 8s & 12s, Multiply
                  </option>
                  <option value="timesTables_4,8,12_divide">
                    4s, 8s & 12s, Divide
                  </option>
                  <option value="timesTables_4,8,12_mixed">
                    4s, 8s & 12s, Mixed
                  </option>
                  <option value="timesTables_4,6,7,8_times">
                    4s, 6s, 7s & 8s, Multiply
                  </option>
                  <option value="timesTables_4,6,7,8_divide">
                    4s, 6s, 7s & 8s, Divide
                  </option>
                  <option value="timesTables_4,6,7,8_mixed">
                    4s, 6s, 7s & 8s, Mixed
                  </option>
                  <option value="timesTables_11,12_times">
                    11s & 12s, Multiply
                  </option>
                  <option value="timesTables_11,12_divide">
                    11s & 12s, Divide
                  </option>
                  <option value="timesTables_11,12_mixed">
                    11s & 12s, Mixed
                  </option>
                </optgroup>
                <optgroup label="Single">
                  <option value="timesTables_2_times">
                    2s, Multiply
                  </option>
                  <option value="timesTables_2_divide">
                    2s, Divide
                  </option>
                  <option value="timesTables_2_mixed">
                    2s, Mixed
                  </option>
                  <option value="timesTables_3_times">
                    3s, Multiply
                  </option>
                  <option value="timesTables_3_divide">
                    3s, Divide
                  </option>
                  <option value="timesTables_3_mixed">
                    3s, Mixed
                  </option>
                  <option value="timesTables_4_times">
                    4s, Multiply
                  </option>
                  <option value="timesTables_4_divide">
                    4s, Divide
                  </option>
                  <option value="timesTables_4_mixed">
                    4s, Mixed
                  </option>
                  <option value="timesTables_5_times">
                    5s, Multiply
                  </option>
                  <option value="timesTables_5_divide">
                    5s, Divide
                  </option>
                  <option value="timesTables_5_mixed">
                    5s, Mixed
                  </option>
                  <option value="timesTables_6_times">
                    6s, Multiply
                  </option>
                  <option value="timesTables_6_divide">
                    6s, Divide
                  </option>
                  <option value="timesTables_6_mixed">
                    6s, Mixed
                  </option>
                  <option value="timesTables_7_times">
                    7s, Multiply
                  </option>
                  <option value="timesTables_7_divide">
                    7s, Divide
                  </option>
                  <option value="timesTables_7_mixed">
                    7s, Mixed
                  </option>
                  <option value="timesTables_8_times">
                    8s, Multiply
                  </option>
                  <option value="timesTables_8_divide">
                    8s, Divide
                  </option>
                  <option value="timesTables_8_mixed">
                    8s, Mixed
                  </option>
                  <option value="timesTables_9_times">
                    9s, Multiply
                  </option>
                  <option value="timesTables_9_divide">
                    9s, Divide
                  </option>
                  <option value="timesTables_9_mixed">
                    9s, Mixed
                  </option>
                  <option value="timesTables_10_times">
                    10s, Multiply
                  </option>
                  <option value="timesTables_10_divide">
                    10s, Divide
                  </option>
                  <option value="timesTables_10_mixed">
                    10s, Mixed
                  </option>
                  <option value="timesTables_11_times">
                    11s, Multiply
                  </option>
                  <option value="timesTables_11_divide">
                    11s, Divide
                  </option>
                  <option value="timesTables_11_mixed">
                    11s, Mixed
                  </option>
                  <option value="timesTables_12_times">
                    12s, Multiply
                  </option>
                  <option value="timesTables_12_divide">
                    12s, Divide
                  </option>
                  <option value="timesTables_12_mixed">
                    12s, Mixed
                  </option>
                </optgroup>
                <optgroup label="Related Facts">
                  <option value="timesTables_2,3,4,5,6,7,8,9,10,11,12_times_related">
                    2 - 12s, Multiply
                  </option>
                  <option value="timesTables_2,3,4,5,6,7,8,9,10,11,12_divide_related">
                    2 - 12s, Divide
                  </option>
                  <option value="timesTables_2,3,4,5,6,7,8,9,10,11,12_mixed_related">
                    2 - 12s, Mixed
                  </option>
                  <option value="timesTables_2,5,10_times_related">
                    2s, 5s & 10s, Multiply
                  </option>
                  <option value="timesTables_2,5,10_divide_related">
                    2s, 5s & 10s, Divide
                  </option>
                  <option value="timesTables_2,5,10_mixed_related">
                    2s, 5s & 10s, Mixed
                  </option>
                  <option value="timesTables_3,4,8_times_related">
                    3s, 4s & 8s, Multiply
                  </option>
                  <option value="timesTables_3,4,8_divide_related">
                    3s, 4s & 8s, Divide
                  </option>
                  <option value="timesTables_3,4,8_mixed_related">
                    3s, 4s & 8s, Mixed
                  </option>
                  <option value="timesTables_2,3,4,5,8,10_times_related">
                    2s, 3s, 4s, 5s, 8s & 10s, Multiply
                  </option>
                  <option value="timesTables_2,3,4,5,8,10_divide_related">
                    2s, 3s, 4s, 5s, 8s & 10s, Divide
                  </option>
                  <option value="timesTables_2,3,4,5,8,10_mixed_related">
                    2s, 3s, 4s, 5s, 8s & 10s, Mixed
                  </option>
                  <option value="timesTables_3,6,9_times_related">
                    3s, 6s & 9s, Multiply
                  </option>
                  <option value="timesTables_3,6,9_divide_related">
                    3s, 6s & 9s, Divide
                  </option>
                  <option value="timesTables_3,6,9_mixed_related">
                    3s, 6s & 9s, Mixed
                  </option>
                  <option value="timesTables_4,8,12_times_related">
                    4s, 8s & 12s, Multiply
                  </option>
                  <option value="timesTables_4,8,12_divide_related">
                    4s, 8s & 12s, Divide
                  </option>
                  <option value="timesTables_4,8,12_mixed_related">
                    4s, 8s & 12s, Mixed
                  </option>
                  <option value="timesTables_4,6,7,8_times_related">
                    4s, 6s, 7s & 8s, Multiply
                  </option>
                  <option value="timesTables_4,6,7,8_divide_related">
                    4s, 6s, 7s & 8s, Divide
                  </option>
                  <option value="timesTables_4,6,7,8_mixed_related">
                    4s, 6s, 7s & 8s, Mixed
                  </option>
                  <option value="timesTables_11,12_times_related">
                    11s & 12s, Multiply
                  </option>
                  <option value="timesTables_11,12_divide_related">
                    11s & 12s, Divide
                  </option>
                  <option value="timesTables_11,12_mixed_related">
                    11s & 12s, Mixed
                  </option>
                  <option value="timesTables_2_times_related">
                    2s, Multiply
                  </option>
                  <option value="timesTables_2_divide_related">
                    2s, Divide
                  </option>
                  <option value="timesTables_2_mixed_related">
                    2s, Mixed
                  </option>
                  <option value="timesTables_3_times_related">
                    3s, Multiply
                  </option>
                  <option value="timesTables_3_divide_related">
                    3s, Divide
                  </option>
                  <option value="timesTables_3_mixed_related">
                    3s, Mixed
                  </option>
                  <option value="timesTables_4_times_related">
                    4s, Multiply
                  </option>
                  <option value="timesTables_4_divide_related">
                    4s, Divide
                  </option>
                  <option value="timesTables_4_mixed_related">
                    4s, Mixed
                  </option>
                  <option value="timesTables_5_times_related">
                    5s, Multiply
                  </option>
                  <option value="timesTables_5_divide_related">
                    5s, Divide
                  </option>
                  <option value="timesTables_5_mixed_related">
                    5s, Mixed
                  </option>
                  <option value="timesTables_6_times_related">
                    6s, Multiply
                  </option>
                  <option value="timesTables_6_divide_related">
                    6s, Divide
                  </option>
                  <option value="timesTables_6_mixed_related">
                    6s, Mixed
                  </option>
                  <option value="timesTables_7_times_related">
                    7s, Multiply
                  </option>
                  <option value="timesTables_7_divide_related">
                    7s, Divide
                  </option>
                  <option value="timesTables_7_mixed_related">
                    7s, Mixed
                  </option>
                  <option value="timesTables_8_times_related">
                    8s, Multiply
                  </option>
                  <option value="timesTables_8_divide_related">
                    8s, Divide
                  </option>
                  <option value="timesTables_8_mixed_related">
                    8s, Mixed
                  </option>
                  <option value="timesTables_9_times_related">
                    9s, Multiply
                  </option>
                  <option value="timesTables_9_divide_related">
                    9s, Divide
                  </option>
                  <option value="timesTables_9_mixed_related">
                    9s, Mixed
                  </option>
                  <option value="timesTables_10_times_related">
                    10s, Multiply
                  </option>
                  <option value="timesTables_10_divide_related">
                    10s, Divide
                  </option>
                  <option value="timesTables_10_mixed_related">
                    10s, Mixed
                  </option>
                  <option value="timesTables_11_times_related">
                    11s, Multiply
                  </option>
                  <option value="timesTables_11_divide_related">
                    11s, Divide
                  </option>
                  <option value="timesTables_11_mixed_related">
                    11s, Mixed
                  </option>
                  <option value="timesTables_12_times_related">
                    12s, Multiply
                  </option>
                  <option value="timesTables_12_divide_related">
                    12s, Divide
                  </option>
                  <option value="timesTables_12_mixed_related">
                    12s, Mixed
                  </option>
                </optgroup>
                <optgroup label="Related Facts Including Decimals">
                  <option value="timesTables_2,3,4,5,6,7,8,9,10,11,12_times_relateddecimals">
                    2 - 12s, Multiply
                  </option>
                  <option value="timesTables_2,3,4,5,6,7,8,9,10,11,12_divide_relateddecimals">
                    2 - 12s, Divide
                  </option>
                  <option value="timesTables_2,3,4,5,6,7,8,9,10,11,12_mixed_relateddecimals">
                    2 - 12s, Mixed
                  </option>
                  <option value="timesTables_2,5,10_times_relateddecimals">
                    2s, 5s & 10s, Multiply
                  </option>
                  <option value="timesTables_2,5,10_divide_relateddecimals">
                    2s, 5s & 10s, Divide
                  </option>
                  <option value="timesTables_2,5,10_mixed_relateddecimals">
                    2s, 5s & 10s, Mixed
                  </option>
                  <option value="timesTables_3,4,8_times_relateddecimals">
                    3s, 4s & 8s, Multiply
                  </option>
                  <option value="timesTables_3,4,8_divide_relateddecimals">
                    3s, 4s & 8s, Divide
                  </option>
                  <option value="timesTables_3,4,8_mixed_relateddecimals">
                    3s, 4s & 8s, Mixed
                  </option>
                  <option value="timesTables_2,3,4,5,8,10_times_relateddecimals">
                    2s, 3s, 4s, 5s, 8s & 10s, Multiply
                  </option>
                  <option value="timesTables_2,3,4,5,8,10_divide_relateddecimals">
                    2s, 3s, 4s, 5s, 8s & 10s, Divide
                  </option>
                  <option value="timesTables_2,3,4,5,8,10_mixed_relateddecimals">
                    2s, 3s, 4s, 5s, 8s & 10s, Mixed
                  </option>
                  <option value="timesTables_3,6,9_times_relateddecimals">
                    3s, 6s & 9s, Multiply
                  </option>
                  <option value="timesTables_3,6,9_divide_relateddecimals">
                    3s, 6s & 9s, Divide
                  </option>
                  <option value="timesTables_3,6,9_mixed_relateddecimals">
                    3s, 6s & 9s, Mixed
                  </option>
                  <option value="timesTables_4,8,12_times_relateddecimals">
                    4s, 8s & 12s, Multiply
                  </option>
                  <option value="timesTables_4,8,12_divide_relateddecimals">
                    4s, 8s & 12s, Divide
                  </option>
                  <option value="timesTables_4,8,12_mixed_relateddecimals">
                    4s, 8s & 12s, Mixed
                  </option>
                  <option value="timesTables_4,6,7,8_times_relateddecimals">
                    4s, 6s, 7s & 8s, Multiply
                  </option>
                  <option value="timesTables_4,6,7,8_divide_relateddecimals">
                    4s, 6s, 7s & 8s, Divide
                  </option>
                  <option value="timesTables_4,6,7,8_mixed_relateddecimals">
                    4s, 6s, 7s & 8s, Mixed
                  </option>
                  <option value="timesTables_11,12_times_relateddecimals">
                    11s & 12s, Multiply
                  </option>
                  <option value="timesTables_11,12_divide_relateddecimals">
                    11s & 12s, Divide
                  </option>
                  <option value="timesTables_11,12_mixed_relateddecimals">
                    11s & 12s, Mixed
                  </option>
                  <option value="timesTables_2_times_relateddecimals">
                    2s, Multiply
                  </option>
                  <option value="timesTables_2_divide_relateddecimals">
                    2s, Divide
                  </option>
                  <option value="timesTables_2_mixed_relateddecimals">
                    2s, Mixed
                  </option>
                  <option value="timesTables_3_times_relateddecimals">
                    3s, Multiply
                  </option>
                  <option value="timesTables_3_divide_relateddecimals">
                    3s, Divide
                  </option>
                  <option value="timesTables_3_mixed_relateddecimals">
                    3s, Mixed
                  </option>
                  <option value="timesTables_4_times_relateddecimals">
                    4s, Multiply
                  </option>
                  <option value="timesTables_4_divide_relateddecimals">
                    4s, Divide
                  </option>
                  <option value="timesTables_4_mixed_relateddecimals">
                    4s, Mixed
                  </option>
                  <option value="timesTables_5_times_relateddecimals">
                    5s, Multiply
                  </option>
                  <option value="timesTables_5_divide_relateddecimals">
                    5s, Divide
                  </option>
                  <option value="timesTables_5_mixed_relateddecimals">
                    5s, Mixed
                  </option>
                  <option value="timesTables_6_times_relateddecimals">
                    6s, Multiply
                  </option>
                  <option value="timesTables_6_divide_relateddecimals">
                    6s, Divide
                  </option>
                  <option value="timesTables_6_mixed_relateddecimals">
                    6s, Mixed
                  </option>
                  <option value="timesTables_7_times_relateddecimals">
                    7s, Multiply
                  </option>
                  <option value="timesTables_7_divide_relateddecimals">
                    7s, Divide
                  </option>
                  <option value="timesTables_7_mixed_relateddecimals">
                    7s, Mixed
                  </option>
                  <option value="timesTables_8_times_relateddecimals">
                    8s, Multiply
                  </option>
                  <option value="timesTables_8_divide_relateddecimals">
                    8s, Divide
                  </option>
                  <option value="timesTables_8_mixed_relateddecimals">
                    8s, Mixed
                  </option>
                  <option value="timesTables_9_times_relateddecimals">
                    9s, Multiply
                  </option>
                  <option value="timesTables_9_divide_relateddecimals">
                    9s, Divide
                  </option>
                  <option value="timesTables_9_mixed_relateddecimals">
                    9s, Mixed
                  </option>
                  <option value="timesTables_10_times_relateddecimals">
                    10s, Multiply
                  </option>
                  <option value="timesTables_10_divide_relateddecimals">
                    10s, Divide
                  </option>
                  <option value="timesTables_10_mixed_relateddecimals">
                    10s, Mixed
                  </option>
                  <option value="timesTables_11_times_relateddecimals">
                    11s, Multiply
                  </option>
                  <option value="timesTables_11_divide_relateddecimals">
                    11s, Divide
                  </option>
                  <option value="timesTables_11_mixed_relateddecimals">
                    11s, Mixed
                  </option>
                  <option value="timesTables_12_times_relateddecimals">
                    12s, Multiply
                  </option>
                  <option value="timesTables_12_divide_relateddecimals">
                    12s, Divide
                  </option>
                  <option value="timesTables_12_mixed_relateddecimals">
                    12s, Mixed
                  </option>
                </optgroup>
              </select>
            </div>
            <div v-if="numberGameType == 'powerTen'" @change="didChange">
              <select v-model="list" class="form-control">
                <option value="" selected disabled>
                  -- Choose a game --
                </option>
                <optgroup label="Whole Numbers">
                  <option value="powerTen_10_times">
                    10s, Multiply
                  </option>
                  <option value="powerTen_10_divide">
                    10s, Divide
                  </option>
                  <option value="powerTen_10_mixed">
                    10s, Mixed
                  </option>
                  <option value="powerTen_100_times">
                    100s, Multiply
                  </option>
                  <option value="powerTen_100_divide">
                    100s, Divide
                  </option>
                  <option value="powerTen_100_mixed">
                    100s, Mixed
                  </option>
                  <option value="powerTen_10,100_times">
                    10s & 100s, Multiply
                  </option>
                  <option value="powerTen_10,100_divide">
                    10s & 100s, Divide
                  </option>
                  <option value="powerTen_10,100_mixed">
                    10s & 100s, Mixed
                  </option>
                  <option value="powerTen_1000_times">
                    1000s, Multiply
                  </option>
                  <option value="powerTen_1000_divide">
                    1000s, Divide
                  </option>
                  <option value="powerTen_1000_mixed">
                    1000s, Mixed
                  </option>
                  <option value="powerTen_10,100,1000_times">
                    10s, 100s & 1000s, Multiply
                  </option>
                  <option value="powerTen_10,100,1000_divide">
                    10s, 100s & 1000s, Divide
                  </option>
                  <option value="powerTen_10,100,1000_mixed">
                    10s, 100s & 1000s, Mixed
                  </option>
                </optgroup>
                <optgroup label="Decimals">
                  <option value="powerTenDecimals_10_times">
                    10s, Multiply
                  </option>
                  <option value="powerTenDecimals_10_divide">
                    10s, Divide
                  </option>
                  <option value="powerTenDecimals_10_mixed">
                    10s, Mixed
                  </option>
                  <option value="powerTenDecimals_100_times">
                    100s, Multiply
                  </option>
                  <option value="powerTenDecimals_100_divide">
                    100s, Divide
                  </option>
                  <option value="powerTenDecimals_100_mixed">
                    100s, Mixed
                  </option>
                  <option value="powerTenDecimals_10,100_times">
                    10s & 100s, Multiply
                  </option>
                  <option value="powerTenDecimals_10,100_divide">
                    10s & 100s, Divide
                  </option>
                  <option value="powerTenDecimals_10,100_mixed">
                    10s & 100s, Mixed
                  </option>
                  <option value="powerTenDecimals_1000_times">
                    1000s, Multiply
                  </option>
                  <option value="powerTenDecimals_1000_divide">
                    1000s, Divide
                  </option>
                  <option value="powerTenDecimals_1000_mixed">
                    1000s, Mixed
                  </option>
                  <option value="powerTenDecimals_10,100,1000_times">
                    10s, 100s & 1000s, Multiply
                  </option>
                  <option value="powerTenDecimals_10,100,1000_divide">
                    10s, 100s & 1000s, Divide
                  </option>
                  <option value="powerTenDecimals_10,100,1000_mixed">
                    10s, 100s & 1000s, Mixed
                  </option>
                </optgroup>
              </select>
            </div>
            <div v-if="numberGameType == 'addSubtract'" @change="didChange">
              <select v-model="list" class="form-control">
                <option value="" selected disabled>
                  -- Choose a game --
                </option>
                <option value="addSubtract_10_add_within">
                  Within 10, Add
                </option>
                <option value="addSubtract_10_subtract_within">
                  Within 10, Subtract
                </option>
                <option value="addSubtract_10_mixed_within">
                  Within 10, Mixed
                </option>
                <option value="addSubtract_20_add_within">
                  Within 20, Add
                </option>
                <option value="addSubtract_20_subtract_within">
                  Within 20, Subtract
                </option>
                <option value="addSubtract_20_mixed_within">
                  Within 20, Mixed
                </option>
                <option value="addSubtract_100_add_within">
                  Within 100, Add
                </option>
                <option value="addSubtract_100_subtract_within">
                  Within 100, Subtract
                </option>
                <option value="addSubtract_100_mixed_within">
                  Within 100, Mixed
                </option>
                <option value="addSubtract_99_add">
                  2 digits, Add
                </option>
                <option value="addSubtract_99_subtract">
                  2 digits, Subtract
                </option>
                <option value="addSubtract_99_mixed">
                  2 digits, Mixed
                </option>
                <option value="addSubtract_999_add">
                  3 digits, Add
                </option>
                <option value="addSubtract_999_subtract">
                  3 digits, Subtract
                </option>
                <option value="addSubtract_999_mixed">
                  3 digits, Mixed
                </option>
                <option value="addSubtract_9999_add">
                  4 digits, Add
                </option>
                <option value="addSubtract_9999_subtract">
                  4 digits, Subtract
                </option>
                <option value="addSubtract_9999_mixed">
                  4 digits, Mixed
                </option>
              </select>
            </div>
            <div v-if="numberGameType == 'more'" @change="didChange">
              <select v-model="list" class="form-control">
                <option value="" selected disabled>
                  -- Choose a game --
                </option>
                <optgroup label="Year 4 MTC">
                  <option value="timesTables_2,3,4,5,6,7,8,9,10,11,12_times_mtc">
                    Year 4 MTC Simulator
                  </option>
                </optgroup>
                <optgroup label="Negative Numbers">
                  <option value="more_neg_add">
                    Counting through zero, Add
                  </option>
                  <option value="more_neg_subtract">
                    Counting through zero, Subtract
                  </option>
                  <option value="more_neg_mixed">
                    Counting through zero, Mixed
                  </option>
                </optgroup>
              </select>
            </div>
          </form>
          <h4>Average time per correct answer (s)</h4>
          <div id="chart1" class="ct-chart" />
        </div>
        <div v-else class="box-body text-center content">
          <h2>Premium Feature</h2>
          <p>Upgrade to premium to use this feature.</p>
          <router-link v-if="isAdmin" to="/manage-subscription" class="btn button is-link" data-dismiss="modal">
            Upgrade
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { request } from '@/edshed-common/api'
import $ from 'jquery'
import moment from 'moment'
import Chartist from 'chartist'
import VueChartist from 'v-chartist'
import Switches from 'vue-switches'
import _ from 'lodash'
require('chartist-plugin-legend')

export default {
  name: 'GroupCompetenceMaths',
  components: {
    VueChartist,
    Chartist,
    Switches
  },
  props: {
    group: null
  },
  data (router) {
    return {
      loading: '',
      historyData: null,
      response: 'Data Loading...',
      chart: null,
      numberGameType: 'numberBonds',
      list: 'numberBonds_10_add'
    }
  },
  computed: {
    isAdmin () {
      return (this.$store.state.user.school.admin === 1)
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.getHistoryData()
    })
  },
  methods: {
    didChange () {
      this.getHistoryData()
    },
    resetList () {
      this.list = ''
    },
    renderChart () {
      let series = []

      // now we need a series for each word
      // get all words in all plays in case of changes
      // var points = []
      for (let i = 0; i < this.historyData.length; i++) {
        if (this.historyData[i].key === this.list) {
          series.push({ x: moment(this.historyData[i].timestamp + ' 00:00:00', 'YYYY/MM/DD HH:mm:ss').toDate(), y: this.historyData[i].duration / (this.historyData[i].correct_count * 1000) })
        }
      }
      // this.chart = null
      series = _.chain(series)
        .groupBy('x')
        .map(function (g, k) {
          return {
            x: moment(k).toDate(),
            y: _.chain(g)
              .map('y')
              .reduce(function (x, y) { return x + y })
              .value() / g.length
          }
        })
        .value()
      const aggSer = { name: 'Average time', data: series }
      console.log(aggSer)
      this.chart = new Chartist.Line('#chart1', { series: [aggSer] },
        {
          axisX: {
            type: Chartist.FixedScaleAxis,
            divisor: 5,
            labelInterpolationFnc (value) {
              return moment(value).format('MMM D')
            }
          },
          fullWidth: true,
          chartPadding: {
            right: 40
          },
          lineSmooth: Chartist.Interpolation.cardinal({
            tension: 0.1,
            fillHoles: true
          }),
          low: 0,
          height: 300
        })
    },
    // renderChart () {
    //   var series = []

    //   if (this.chartType === 'list') {
    //     // now we need a series for each word
    //     // get all words in all plays in case of changes
    //     var words = []
    //     for (var i = 0; i < this.historyData.length; i++) {
    //       for (var j = 0; j < this.historyData[i].resultsData.length; j++) {
    //         if (this.historyData[i].list_ident === this.selectedList && this.historyData[i].resultsData[j].word && this.historyData[i].resultsData[j].word !== '') {
    //           words.push(this.historyData[i].resultsData[j].word)
    //           // console.log('list ident: ' + this.selectedList)
    //           // console.log(this.historyData[i].list_ident)
    //           // console.log('adding ' + this.historyData[i].resultsData[j].word)
    //         }
    //       }
    //     }
    //     words = _.uniq(words)
    //     // create a series for each word
    //     var aggSer = {name: 'List average', data: []}
    //     for (var k = 0; k < words.length; k++) {
    //       var dat = this.getChartSeriesDataForWord(words[k])
    //       // console.log('dat: ' + JSON.stringify(dat))
    //       if (!this.aggregate) {
    //         var ser = {name: words[k], data: dat}
    //         series.push(ser)
    //       } else {
    //         var dta = aggSer.data
    //         dta = dta.concat(dat)
    //         aggSer.data = dta
    //       }
    //     }
    //     if (this.aggregate) {
    //       // var dates = []
    //       var newData = []
    //       for (var l = 0; l < aggSer.data.length; l++) {
    //         // dates.push(aggSer.dta.data[l].x)
    //         var x = moment(aggSer.data[l].x).format('YYYY/MM/DD')
    //         if (!newData[x]) {
    //           newData[x] = []
    //         }
    //         newData[x].push(aggSer.data[l].y)
    //       }
    //       var chartData = []
    //       for (var key in newData) {
    //         var d = {x: moment(key, 'YYYY/MM/DD').toDate(), y: newData[key].reduce((total, score) => total + score) / newData[key].length}
    //         chartData.push(d)
    //       }
    //       // console.log('aggser: ' + JSON.stringify(chartData))
    //       chartData = _.sortBy(chartData, ['x'])
    //       aggSer.data = chartData
    //       // console.log('aggser: ' + JSON.stringify(aggSer))
    //       series.push(aggSer)
    //     }
    //   } else { // word
    //     // one series
    //     var dt = this.getChartSeriesDataForWord(this.selectedWord)
    //     var s = {name: this.selectedWord, data: dt}
    //     series.push(s)
    //   }
    //   // this.chart = null

    //   this.chart = new Chartist.Line('#chart1', {series: series},
    //     {
    //       axisX: {
    //         type: Chartist.FixedScaleAxis,
    //         divisor: 5,
    //         labelInterpolationFnc: function (value) {
    //           return moment(value).format('MMM D')
    //         }
    //       },
    //       fullWidth: true,
    //       chartPadding: {
    //         right: 40
    //       },
    //       lineSmooth: Chartist.Interpolation.cardinal({
    //         tension: 0.1,
    //         fillHoles: true
    //       }),
    //       high: 100,
    //       low: 0,
    //       height: 300,
    //       plugins: [
    //         Chartist.plugins.legend()
    //       ]
    //     })
    // },
    getChartSeriesDataForWord (word) {
      const d = []
      for (let i = 0; i < this.historyData.length; i++) {
        for (let j = 0; j < this.historyData[i].resultsData.length; j++) {
          if (this.historyData[i].resultsData[j].word.toLowerCase() === word.toLowerCase()) {
            const date = moment(this.historyData[i].timestamp).format('YYYY/MM/DD')
            const dp = { d: date, y: this.historyData[i].resultsData[j].correct }
            d.push(dp)
          }
        }
      }
      let dtar = []
      for (let k = 0; k < d.length; k++) {
        dtar.push(d[k].d)
      }
      dtar = _.uniq(dtar)

      const dt = []
      for (let l = 0; l < dtar.length; l++) {
        // get count of elements with key by filtering
        const df = _.filter(d, { d: dtar[l] })

        // average by reduce => sum & divide by length
        const len = df.length
        const sum = df.reduce(function (total, num) { return total + parseInt(num.y) }, 0)
        const avg = 100 * sum / len
        // create new el
        const el = { x: moment(dtar[l] + ' 00:00:00', 'YYYY/MM/DD HH:mm:ss').toDate(), y: avg }
        dt.push(el)
      }
      return dt
    },
    showChartFor (type, key) {
      this.chartType = type
      if (type === 'list') {
        this.selectedList = key
      } else {
        this.selectedWord = key
      }
      this.renderChart()
    },
    getHistoryData () {
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      // var options = {type: this.chartType, key: this.list}
      request('GET', 'users/me/school/groups/' + this.group + '/mathshistory/' + this.list, null)
        .then((response) => {
          const data = response.data
          this.historyData = data.data

          if (!this.historyData || this.historyData.length === 0) {
            this.response = 'No Groups'
          }

          // for (var i = 0; i < this.historyData.length; i++) {
          //   var resArr = this.historyData[i].results.split(',')
          //   var resArr3 = []
          //   for (var j = 0; j < resArr.length; j++) {
          //     var resArr2 = resArr[j].split(':')
          //     var row = {word: resArr2[0], correct: resArr2[1], answerGiven: resArr2[2]}
          //     resArr3.push(row)
          //   }
          //   this.historyData[i].resultsData = resArr3
          // }

          this.renderChart()
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
          // console.log('setting history: ' + this.historyData)
          // this.$emit('setHistoryData', this.historyData)
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response && error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    dateFromServerDate (dt) {
      if (dt === null || dt === '' || !moment(dt).isValid()) {
        return null
      }
      const m = moment(dt)
      // var utcOffset = new Date().getTimezoneOffset()
      // m.add({minutes: utcOffset})
      return m.format('DD/MM/YYYY HH:mm')
    },
    getAllLists () {
      const lists = []
      for (let i = 0; i < this.historyData.length; i++) {
        const l = { title: this.historyData[i].list_title, ident: this.historyData[i].list_ident }
        lists.push(l)
      }
      this.allLists = _.sortBy(_.uniqBy(lists, 'ident'), 'title')
    },
    getAllWords () {
      const words = []
      for (let i = 0; i < this.historyData.length; i++) {
        for (let j = 0; j < this.historyData[i].resultsData.length; j++) {
          if (this.historyData[i].resultsData[j].word && this.historyData[i].resultsData[j].word !== '') {
            words.push(this.historyData[i].resultsData[j].word)
          }
        }
      }
      this.allWords = _.orderBy(_.uniqBy(words), [word => word.toLowerCase()], ['asc'])
    },
    showGameDetail (gameId) {
      $('#gameDetail' + gameId).toggle()
    },
    toggleLoading () {
      this.loading = (this.loading === '') ? 'loading' : ''
    }
  }
}
</script>
