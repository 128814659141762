<template>
  <div class="box box-danger">
    <div class="box-header with-border content">
      <h2>Words Spelled <span class="tag is-dark">PREMIUM</span></h2>
      <p>Words spelled in the last 7 days. Select words then click "Create List" to make a personalised list.</p>
      <div class="box-tools pull-right">
        <button class="is-pulled-right button" @click="createList">
          <span class="icon"><i class="mdi mdi-plus" /></span> <span>Create List</span>
        </button>
      </div>
    </div>
    <div v-if="$store.getters.isPremium || $store.getters.hasNumber" class="box-body" style="overflow-x: auto;">
      <b-table
        v-if="wordsArr"
        :data.sync="wordsArr"
        :paginated="true"
        :per-page="20"
        :mobile-cards="false"
        :striped="true"
        :selectable="false"
        :checked-rows.sync="checkedRows"
        checkable
        :sort="false"
      >
        <template slot-scope="props">
          <b-table-column field="word" label="Word" width="100" :custom-sort="sortWords" sortable>
            {{ props.row[0].word }}
          </b-table-column>
          <b-table-column label="History">
            <div class="zebraOuter">
              <div v-for="(gp,ind) in props.row" :key="'word-'+ gp.word + ind" :class="{'zebraInner':true, 'is-correct': gp.correct == 1}">
                <abbr v-if="gp.correct == 1" :title="gp.name + ', ' + dateFromServerDate(gp.timestamp)"><i class="fas fa-check" /></abbr>
                <abbr v-else :title="gp.name + ', ' + dateFromServerDate(gp.timestamp)">{{ gp.answer_given }}</abbr>
              </div>
            </div>
          </b-table-column>
        </template>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered content">
              <p>
                <b-icon
                  custom-class="far"
                  pack="fa"
                  icon="frown"
                  size="is-large"
                />
              </p>
              <p>Nothing here.</p>
            </div>
          </section>
        </template>
      </b-table>
      <div v-if="response" class="alert alert-error">
        <p>{{ response }}</p>
      </div>
    </div>
    <div v-else class="box-body text-center content">
      <h2>Premium Feature</h2>
      <p>Upgrade to premium to use this feature.</p>
      <router-link v-if="isAdmin" to="/manage-subscription" class="btn button is-link" data-dismiss="modal">
        Upgrade
      </router-link>
    </div>
  </div>
  </div>
</template>

<script>
import { request } from '@/edshed-common/api'
import moment from 'moment'

export default {
  name: 'GroupWords',
  props: {
    group: null
  },
  data (router) {
    return {
      loading: '',
      wordData: null,
      wordsArr: null,
      response: null,
      checkedRows: []
    }
  },
  computed: {
    isAdmin () {
      return (this.$store.state.user.school.admin === 1)
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.getWordsData()
    })
  },
  methods: {
    getWordsData () {
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      console.log('aa')
      request('GET', 'users/me/school/groups/' + this.group.id + '/wordsspelled', null)
        .then((response) => {
          console.log(response.data)
          const data = response.data
          this.wordData = data.data

          if (!this.wordData || this.wordData.length === 0) {
            this.response = 'No Words'
          } else {
            this.processWordData()
          }

          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response && error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    processWordData () {
      // loop through, create array of words grouped by "word"
      const wordsObject = {}
      for (let i = 0; i < this.wordData.length; i++) {
        if (!wordsObject[this.wordData[i].word]) {
          wordsObject[this.wordData[i].word] = []
        }
        wordsObject[this.wordData[i].word].push(this.wordData[i])
      }
      // console.log(wordsObject)

      this.wordsArr = Object.keys(wordsObject).map(i => wordsObject[i])
      console.log(this.wordsArr)
    },
    dateFromServerDate (dt) {
      if (dt === null || dt === '' || !moment(dt).isValid()) {
        return null
      }
      const m = moment(dt)
      // var utcOffset = new Date().getTimezoneOffset()
      // m.add({minutes: utcOffset})
      return m.format('DD/MM/YYYY HH:mm')
    },
    toggleLoading () {
      this.loading = (this.loading === '') ? 'loading' : ''
    },
    sortWords (a, b, isAsc) {
      if (a[0].word.toLowerCase() < b[0].word.toLowerCase()) {
        if (isAsc) {
          return -1
        } else {
          return 1
        }
      }
      if (a[0].word.toLowerCase() > b[0].word.toLowerCase()) {
        if (isAsc) {
          return 1
        } else {
          return -1
        }
      }
      return 0
    },
    createList () {
      if (this.checkedRows.length === 0) {
        alert('No words selected.')
        return
      }
      const p = prompt('Enter name for list')

      if (p != null && p.length > 3) {
        // var wordsprocessed = this.words.replace(/\n/g, ',').replace(/\s/g, ',')
        // var split = wordsprocessed.split(',').filter(function (n) { return n !== '' })
        // var cleanString = split.join(',')
        const words = []
        for (let i = 0; i < this.checkedRows.length; i++) {
          words.push(this.checkedRows[i][0].word)
        }
        const cleanString = words.join(',')
        console.log(cleanString)

        request('POST', 'users/me/lists', { title: p, words: cleanString })
          .then((response) => {
            const list = response.data.list
            if (list) {
              const conf = confirm('View list?')
              if (conf) {
                this.$router.push('/list/' + list.ident)
              }
            } else if (response.data.error) {
              console.log(response.data.error)
              alert('Error: ' + response.data.error)
            }
          })
          .catch((error) => {
            console.log(error)
            this.$store.commit('TOGGLE_LOADING')
            this.toggleLoading()
            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
            this.response = 'Details incorrect'
          })
      }
    }
  }
}
</script>

<style scoped>
  .zebraOuter {
    display: flex;
    flex-flow: row nowrap;
    flex: 1;
    min-width: 0;
    line-height: 2.2;
    color: white;
  }

  .zebraInner {
    background-color:#ff3860;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    flex: 1;
    min-width: 0;
    border-right: 2px solid white;
    overflow: hidden;
  }

  .zebraInner.is-correct {
    background-color: #23d160;

  }
  </style>
