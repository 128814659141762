<template>
  <!-- Main content -->
  <section class="section">
    <div class="row center-block">
      <div :id="passwordExports.length > 0 ? 'contentWrapper' : null" class="col-md-8">
        <div class="box box-info">
          <div class="box-header content">
            <h2 v-if="group">
              {{ group.name }}
            </h2>
            <span v-if="group && group.leagues_disabled === 1" class="tag is-dark">LEAGUES DISABLED</span>
            <div class="box-tools pull-right">
              <button type="button" class="button" @click.prevent="renameGroup">
                <span class="icon"><i class="mdi mdi-pencil" /></span> <span>Edit</span>
              </button>
            </div>
            <div :id="passwordExports.length > 0 ? 'contentWrapper' : null" class="col-md-12">
              <div id="groupActivity" class="ct-chart ct-activity" style="height:150px" />
              <p class="pull-right is-size-7 has-text-gray">
                <b>Gameplays by date</b>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div :id="passwordExports.length > 0 ? 'contentWrapper' : null" class="col-md-4">
        <GroupTeachers v-if="group" :group="group" />
      </div>
    </div>

    <div class="row center-block">
      <div class="col-md-12">
        <div class="tabs is-toggle is-fullwidth" style="margin-bottom: 30px;">
          <ul>
            <li :class="{'is-active': tabIndex == 0}">
              <a @click="dataTabs(0)">
                <span class="icon is-small"><i class="fas fa-users" /></span>
                <span>{{ $store.state.user.locale === 'en_GB' ? 'Pupils' : 'Students' }}</span></a>
            </li>
            <li v-if="$store.getters.hasSpelling" :class="{'is-active': tabIndex == 1}">
              <a @click="dataTabs(1)">
                <span class="icon is-small"><i class="fas fa-book" /></span>
                <span>Spelling Data</span></a>
            </li>
            <li v-if="$store.getters.hasNumber" :class="{'is-active': tabIndex == 2}">
              <a @click="dataTabs(2)">
                <span class="icon is-small"><i class="fas fa-rocket" /></span>
                <span>{{ $store.state.user.locale === 'en_US' ? 'Math' : 'Maths' }} Data</span></a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div v-if="tabIndex == 0" class="row center-block">
      <div :id="passwordExports.length > 0 ? 'contentWrapper' : null" class="col-md-12">
        <GroupPupils v-if="group" :group="group" @passwords-set="setPasswordData" />
      </div>
    </div>
    <div v-if="$store.getters.hasSpelling && tabIndex == 1" :id="passwordExports.length > 0 ? 'contentWrapper' : null" class="col-md-12">
      <GroupWords v-if="group" :group="group" />
    </div>
    <div v-if="$store.getters.hasSpelling && tabIndex == 1" :id="passwordExports.length > 0 ? 'contentWrapper' : null" class="col-md-12">
      <GroupCompetence :group="$route.params.id" />
    </div>

    <div v-if="tabIndex == 2 && $store.getters.hasNumber" class="row center-block">
      <div :id="passwordExports.length > 0 ? 'contentWrapper' : null" class="col-md-12">
        <GroupCompetenceMaths :group="$route.params.id" />
      </div>
    </div>

    <EditGroup v-if="group && showEdit" :name="group.name" :disable-leagues="parseInt(group.leagues_disabled) === 1" @save="saveGroupName" @close="closeEditGroup" />
  </section>
</template>

<script>
import moment from 'moment'
import Chartist from 'chartist'
import VueChartist from 'v-chartist'

import LoginDetails from '@/components/views/components/LoginDetails'
import GroupCompetence from '@/components/views/components/GroupCompetence'
import GroupCompetenceMaths from '@/components/views/components/GroupCompetenceMaths'
import GroupWords from '@/components/views/components/GroupWords'
import EditGroup from '@/components/views/components/EditGroup'
import store from '@/store'
import { request } from '@/edshed-common/api'
import GroupPupils from './components/GroupPupils'
import GroupTeachers from './components/GroupTeachers'

export default {
  name: 'Group',
  components: {
    GroupTeachers,
    GroupPupils,
    GroupCompetence,
    GroupCompetenceMaths,
    GroupWords,
    LoginDetails,
    VueChartist,
    Chartist,
    EditGroup
  },
  data (router) {
    return {
      loading: '',
      group: null,
      response: 'Data Loading...',
      passwordExports: [],
      showEdit: false,
      activityData: null,
      mathsActivityData: null,
      tabIndex: 0
    }
  },
  // Redirect to new home for non-superusers
  beforeRouteEnter (to, from, next) {
    if (store.state.user && store.state.user.superuser) {
      next()
    } else {
      let locale = 'en-gb'
      if (store.state.user && store.state.user.locale && store.state.user.locale) {
        locale = store.state.user.locale.toLowerCase().replace('_', '-')
      }
      window.location.replace(`https://www.edshed.com/${locale}/groups/${to.params.id}`)
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.getGroupData()
      if (this.$store.getters.hasSpelling) {
        this.getActivityData()
      }
      if (this.$store.getters.hasNumber) {
        this.getMathsActivityData()
      }
    })
  }, // passwordExports && group
  // beforeRouteLeave (to, from, next) {
  //   console.log('leaving')
  //   if (this.passwordExports.length > 0 && this.group) {
  //     const answer = window.confirm('Pupil passwords will be lost once you navigate away from this page. If you have not printed / saved them click Cancel.')
  //     if (answer) {
  //       next()
  //     } else {
  //       next(false)
  //     }
  //   } else {
  //     next()
  //   }
  // },
  methods: {
    dataTabs (ind) {
      this.tabIndex = ind
    },
    getGroupData () {
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('GET', 'users/me/school/groups/' + this.$route.params.id, null)
        .then((response) => {
          const data = response.data

          if (!data.group) {
            console.log('NO GROUP')
            this.response = 'No Group'
            this.$router.push('/groups')
          }

          this.group = data.group

          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    renameGroup () {
      this.showEdit = true
    },
    closeEditGroup () {
      this.showEdit = false
    },
    saveGroupName (name, leagues) {
      if (name != null && name !== '') {
        request('PUT', 'users/me/school/groups/' + this.group.id, { name, leaguesDisabled: (leagues ? '0' : '1') })
          .then((response) => {
            this.showEdit = false
            this.getGroupData()
          })
          .catch((error) => {
            console.log(error)
            this.$store.commit('TOGGLE_LOADING')
            this.toggleLoading()

            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }

            this.response = 'Details incorrect'
          })
      }
    },
    setPasswordData (pupils) {
      console.log('passwords set: ' + pupils)
      this.passwordExports = null
      this.passwordExports = pupils
    },
    getActivityData () {
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('GET', 'users/me/school/groups/' + this.$route.params.id + '/activity', null)
        .then((response) => {
          const data = response.data
          this.activityData = data.data
          this.renderActivityChart()
          // console.log('readerData: ' + this.readerData)
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()
          if (error.response && error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    getMathsActivityData () {
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('GET', 'users/me/school/groups/' + this.$route.params.id + '/activitymaths', null)
        .then((response) => {
          const data = response.data
          this.mathsActivityData = data.data
          this.renderActivityChart()
          // console.log('readerData: ' + this.readerData)
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()
          if (error.response && error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    renderActivityChart () {
      const labels = []
      const seriesData = []
      const mathsSeriesData = []

      for (let i = 0; i < this.activityData.length; i++) {
        if (i % 3 === 0) {
          labels.push(moment(this.activityData[i].date).format('MMM D'))
        } else {
          labels.push('')
        }
        if (this.$store.getters.hasSpelling) {
          seriesData.push(parseInt(this.activityData[i].count))
        }
        if (this.$store.getters.hasNumber) {
          mathsSeriesData.push(parseInt(this.mathsActivityData[i].count))
        }
      }

      const series = [seriesData, mathsSeriesData]
      const data = { labels, series }

      this.chart = Chartist.Bar('#groupActivity', data)
    },
    toggleLoading () {
      this.loading = (this.loading === '') ? 'loading' : ''
    }
  }
}
</script>
