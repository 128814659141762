<template>
  <!-- Main content -->
  <section>
    <div class="box box-danger">
      <div class="box-header with-border content">
        <h2 v-if="group">
          {{ $store.state.user.locale === 'en_GB' ? 'Pupils' : 'Students' }}
        </h2>
        <div class="box-tools is-pulled-right">
          <button v-if="passwordExports.length > 0" type="button" class="btn button is-danger" style="margin-right:5px;" @click.prevent="forgetPasswords">
            Forget Passwords
          </button>
          <button v-if="passwordExports.length > 0" class="button is-warning btn-xs" @click.prevent="print">
            Print / Save Login Details
          </button>
          <button type="button" class="button" data-toggle="modal" data-target="#passwordModal" title="Set Passwords">
            <span class="icon"><i class="mdi mdi-lock" /></span> <span>Set Passwords</span>
          </button>
          <button type="button" class="button" data-toggle="modal" data-target="#addPupilsModal" @click="showAddModal">
            <span class="icon"><i class="mdi mdi-plus" /></span> <span>Add {{ $store.state.user.locale === 'en_GB' ? 'Pupils' : 'Students' }}</span>
          </button>
        </div>
        <div v-if="passwordExports.length > 0" class="is-pulled-right">
          <small>N.B. Password data will be lost on logout.</small>
        </div>
      </div>
      <div class="box-body">
        <div style="margin-top:10px;">
          <b-field>
            <b-input
              v-model="searchPhrase"
              placeholder="Search..."
              type="search"
              icon-pack="fas"
              icon="search"
              @input="searchDidChange"
            />
          </b-field>
        </div>
        <b-table
          v-if="filteredPupilData"
          :data.sync="filteredPupilData"
          :paginated="true"
          :per-page="10"
          :mobile-cards="true"
          :striped="true"
          :selectable="false"
          :sort="false"
          style="overflow: scroll"
        >
          <template slot-scope="props">
            <b-table-column field="name" :label="$store.state.user.locale === 'en_GB' ? 'Pupil' : 'Student'" sortable>
              <AvatarView
                v-if="props.row.total_score != null"
                :key="'av-' + props.row.id"
                style="float:left; margin-right:10px;"
                :uniq="'user-'+props.row.id"
                :data="(props.row.avatar && props.row.avatar != '') ? JSON.parse(props.row.avatar) : {}"
                scale-factor="0.35"
              />
              <!-- </b-table-column>
            <b-table-column field="name" label="Name" item-width="10px" sortable> -->
              <br style="clear:both">{{ props.row.name }}<br><small>@{{ props.row.username }}</small><br>
              <span class="badge"><i class="fas fa-tv" /> {{ props.row.display_name == '' ? props.row.name : props.row.display_name }}</span>
              <button class="button is-small" @click.prevent="setDisplayName(props.row)">
                <i class="fas fa-edit" />
              </button>
            </b-table-column>
            <b-table-column field="actions" :custom-key="'act-'+props.row.id">
              <div class="btn-group is-pulled-right">
                <router-link :to="'/pupils/'+props.row.username" class="btn button is-link">
                  Details
                </router-link>
                <button type="button" class="btn button is-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <span class="caret" />
                  <span class="sr-only">Toggle Dropdown</span>
                </button>
                <ul class="dropdown-menu">
                  <li><a href="#" @click.prevent="removePupil (props.row.id)">Remove</a></li>
                </ul>
              </div>
            </b-table-column>
            <b-table-column v-if="$store.getters.isPremium || $store.getters.hasNumber" label="Games">
              <!-- <span class="ct-chart bar spark" :id="'activityBar' + props.row.id" style="width:200px; height: 100px;"></span> -->
              <p :key="'act'+props.row.id">
                <span v-html="historyForPupil(props.row)" />
              </p>
            </b-table-column>
            <b-table-column v-if="$store.getters.hasSpelling" field="score" label="Spelling Shed Score" sortable>
              <small>{{ props.row.score.toLocaleString() }}</small>
            </b-table-column>
            <b-table-column v-if="$store.getters.hasSpelling" field="total_score" label="Total Spelling Score" sortable>
              <small>{{ props.row.total_score.toLocaleString() }}</small>
            </b-table-column>
            <b-table-column v-if="$store.getters.hasNumber" field="score_number" :label="$store.state.user.locale === 'en_US' ? 'Math Shed Score' : 'Maths Shed Score'" sortable>
              <small>{{ props.row.score_number.toLocaleString() }}</small>
            </b-table-column>
            <b-table-column v-if="$store.getters.hasNumber" field="total_score_number" :label="$store.state.user.locale === 'en_US' ? 'Total Math Score' : 'Total Maths Score'" sortable>
              <small>{{ parseInt(props.row.total_score_number).toLocaleString() }}</small>
            </b-table-column>
            <b-table-column field="total_earnings" label="Earnings Balance" sortable>
              <small>🍯 {{ props.row.total_earnings.toLocaleString() }}</small>
            </b-table-column>
          </template>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered content">
                <p>
                  <b-icon
                    custom-class="far"
                    pack="fa"
                    icon="frown"
                    size="is-large"
                  />
                </p>
                <p>Nothing here.</p>
              </div>
            </section>
          </template>
        </b-table>
        <div v-else class="alert">
          <b>{{ response }}</b>
        </div>
      </div>
    </div>
    <AddPupils v-if="showModal" :group="group.id" @pupils-added="didAddPupils" @close="hideAddModal" />
    <!-- Add Pupil Modal -->
    <!-- <AddPupils v-on:pupils-added="didAddPupils" v-if="showModal" v-on:close="hideAddModal" /> -->
    <!-- Set Password Modal -->
    <div id="passwordModal" class="modal" tabindex="-1" role="dialog" aria-labelledby="passwordModalLabel">
      <div class="modal-dialog" role="document" style="margin: 10px auto">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="clearPasswordBeingSet">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 id="myModalLabel" class="modal-title">
              Set Pupil Passwords
            </h4>
          </div>
          <div class="modal-body">
            <p>Enter a password below or click "Set Random" to generate random passwords for all pupils.</p>
            <form style="width:100%">
              <div class="form-group">
                <label for="inputChangePassword">Password</label>
                <input id="inputChangePassword" v-model="passwordBeingSet" class="form-control" placeholder="Password">
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn button" data-dismiss="modal">
              Close
            </button>
            <button type="button" class="btn button is-link" data-dismiss="modal" :disabled="(passwordBeingSet.length > 0) ? 'disabled' : null" @click="didSetRandomPasswords">
              Set Random
            </button>
            <button type="button" class="btn button is-link" data-dismiss="modal" :disabled="(passwordBeingSet.length < 6) ? 'disabled' : null" @click="didSetPasswords">
              Set Password
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Chartist from 'chartist'
import VueChartist from 'v-chartist'
import AddPupils from '@/components/views/components/AddPupils'
import utils from '@/utils'
import loginPDF from '@/loginPDF'
import AvatarView from '@/components/views/AvatarView'
import { request } from '@/edshed-common/api'

export default {
  name: 'GroupPupils',
  components: {
    AddPupils,
    AvatarView,
    VueChartist,
    Chartist
  },
  props: {
    group: null,
    historyData: null
  },
  data (router) {
    return {
      loading: '',
      pupilsData: null,
      showModal: false,
      response: 'Data Loading...',
      passwordBeingSet: '',
      filteredPupilData: null,
      searchPhrase: '',
      charts: {}
    }
  },
  computed: {
    passwordExports () {
      const exp = []
      if (!this.pupilsData) {
        return exp
      }
      for (let i = 0; i < this.pupilsData.length; i++) {
        if (!this.pupilsData[i].password) {
          const pw = this.passwordForUsername(this.pupilsData[i].username)
          if (pw) {
            this.pupilsData[i].password = pw
          }
        }
        if (this.pupilsData[i].password && this.pupilsData[i].password !== '') {
          exp.push(this.pupilsData[i])
        }
      }
      return exp
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.getPupilData()
    })
  },
  methods: {
    historyForPupil (pupil) {
      console.log('HISTORY DATA: ' + pupil.activity)
      // var pupilData = []
      // for (var point of this.historyData) {
      //   // console.log('point: ' + JSON.stringify(point))
      //   if (point.user_id === pupil) {
      //     pupilData.push(point)
      //   }
      // }
      // // console.log(pupilData)
      // if (pupilData.length === 0) {
      //   // return
      // }
      // console.log('b')
      // var timeData = []
      // for (var i = 0; i < 7; i++) {
      //   timeData[i] = []
      //   var testDate = new Date()
      //   testDate.setDate(testDate.getDate() - i)
      //   testDate.setHours(0, 0, 0, 0)
      //   var testDate2 = new Date()
      //   testDate2.setDate(testDate2.getDate() - i + 1)
      //   testDate2.setHours(0, 0, 0, 0)
      // console.log('td ' + testDate)
      // console.log('td2 ' + testDate2)
      // for (var j = 0; j < pupilData.length; j++) {
      //   var pdate = new Date(pupilData[j].timestamp)
      //   pdate.setHours(0, 0, 0, 0)
      //   // console.log((testDate.getTime() + (testDate.getTimezoneOffset() * 60000)))
      //   // console.log(pdate.getTime() + (pdate.getTimezoneOffset() * 60000))
      //   // console.log((testDate2.getTime() + (testDate2.getTimezoneOffset() * 60000)))
      //   // console.log('--')
      //   if (pdate.getTime() + (pdate.getTimezoneOffset() * 60000) >= (testDate.getTime() + (testDate.getTimezoneOffset() * 60000)) &&
      //   pdate.getTime() + (pdate.getTimezoneOffset() * 60000) < (testDate2.getTime() + (testDate2.getTimezoneOffset() * 60000))) {
      //     // console.log('YES')
      //     //console.log('td: ' + pupilData[j])
      //     //timeData[i].push(pupilData[j])
      //   }
      // }
      // }
      // console.log('TIME DATA: ' + timeData)
      // var retArr = []
      // for (var k = 6; k >= 0; k--) {
      //   // retArr.push(timeData[k].length)
      // }
      // console.log('RETARR: ' + retArr)
      // var data = {series: [retArr]}
      // var sparkOptions = {
      //   height: '4.5em',
      //   width: '100%',
      //   onlyInteger: true,
      //   showPoint: false,
      //   fullWidth: true,
      //   chartPadding: {top: 0, right: 0, bottom: 0, left: 0},
      //   axisX: {showGrid: true, showLabel: true, offset: 16},
      //   axisY: {showGrid: true, showLabel: true, offset: 30}
      // }

      const labels = []
      const today = new Date()
      for (let l = 6; l >= 0; l--) {
        const labDate = new Date()
        labDate.setDate(today.getDate() - l)
        if (l === 0) {
          labels.push('Today')
        } else {
          labels.push(['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][labDate.getDay()])
        }
      }
      // console.log(this.charts[pupil])
      // if (this.charts[pupil] !== undefined) {
      //   this.charts[pupil] = Chartist.Bar('#activityBar' + pupil, {series: [retArr], labels: labels}, sparkOptions)
      // }
      const rsa = []
      const rsb = []
      const rsc = []
      const retArr = pupil.activity.spelling.split(',')
      const numArr = pupil.activity.number.split(',')
      for (let m = 0; m < 7; m++) {
        rsa.push('<th>' + labels[m] + '</th>')
        if (this.$store.getters.hasSpelling) {
          rsb.push('<td>' + retArr[m] + '</td>')
        }
        if (this.$store.getters.hasNumber) {
          rsc.push('<td>' + numArr[m] + '</td>')
        }
      }
      let ret = '<table class="gamesTable"><tr>' + rsa.join('') + '</tr>'
      if (this.$store.getters.hasSpelling) {
        ret += '<tr class="activitySpelling">' + rsb.join('') + '</tr>'
      }
      if (this.$store.getters.hasNumber) {
        ret += '<tr class="activityNumber">' + rsc.join('') + '</tr>'
      }
      ret += '</table>'
      return ret
    },
    print () {
      // window.print()
      this.generatePDF()
    },
    generatePDF () {
      loginPDF.generatePDFForUsers(this.passwordExports)
    },
    searchDidChange () {
      console.log(this.searchPhrase)
      if (this.searchPhrase !== '') {
        this.filteredPupilData = []
        console.log('l: ' + this.pupilsData.length)
        for (let i = 0; i < this.pupilsData.length; i++) {
          console.log('l: ' + this.pupilsData.length)
          console.log('i: ' + i)
          console.log(this.pupilsData[i].name.toLowerCase())
          if (this.pupilsData[i].name.toLowerCase().includes(this.searchPhrase.toLowerCase()) ||
            this.pupilsData[i].username.toLowerCase().includes(this.searchPhrase.toLowerCase()) ||
            (this.pupilsData[i].upn && this.pupilsData[i].upn.toLowerCase().includes(this.searchPhrase.toLowerCase())) ||
            (this.pupilsData[i].email && this.pupilsData[i].email.toLowerCase().includes(this.searchPhrase.toLowerCase()))) {
            console.log('adding')
            this.filteredPupilData.push(this.pupilsData[i])
          }
        }
      } else {
        this.filteredPupilData = this.pupilsData
      }
      // this.$forceUpdate
    },
    setDisplayName (pupil) {
      const p = prompt('Enter display name (leave blank to show pupil name)', pupil.display_name)
      if (p) {
        request('PUT', 'users/me/school/pupils/' + pupil.username, { displayName: p })
          .then((response) => {
            const data = response.data
            for (let i = 0; i < this.pupilsData.length; i++) {
              if (this.pupilsData[i].id === pupil.id) {
                this.pupilsData[i].display_name = p
              }
            }
            console.log(data)
          })
          .catch((error) => {
            console.log(error)

            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }

            this.response = 'Details incorrect'
          })
      }
    },
    clearPasswordBeingSet () {
      this.passwordBeingSet = ''
    },
    passwordForUsername (username) {
      if (window.localStorage) {
        return window.localStorage.getItem('password:' + username)
      } else {
        return null
      }
    },
    didSetPasswords () {
      // generate array of user : password
      const ids = []
      for (let i = 0; i < this.pupilsData.length; i++) {
        ids.push({ id: this.pupilsData[i].id, password: this.passwordBeingSet })
      }
      this.setPasswords(ids)
      this.passwordBeingSet = ''
    },
    didSetRandomPasswords () {
      // generate array of user : password
      const ids = []
      for (let i = 0; i < this.pupilsData.length; i++) {
        ids.push({ id: this.pupilsData[i].id, password: this.generatePassword() })
      }
      this.setPasswords(ids)
      this.passwordBeingSet = ''
    },
    setPasswords (pupilIdsArray) {
      if (pupilIdsArray.length === 0) {
        alert('No pupils')
        return
      }
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('PUT', 'users/me/school/pupils', { pupils: pupilIdsArray })
        .then((response) => {
          const pupils = response.data.pupils
          console.log('aaa ' + pupils)
          for (let i = 0; i < this.pupilsData.length; i++) {
            for (let j = 0; j < pupils.length; j++) {
              if (this.pupilsData[i].id === pupils[j].id) {
                this.$set(this.pupilsData[i], 'password', pupils[j].password)
                if (window.localStorage) {
                  window.localStorage.setItem('password:' + this.pupilsData[i].username, pupils[j].password)
                }
              }
            }
          }
          this.$emit('passwords-set', this.pupilsData)
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    generatePassword () {
      return utils.generatePassword()
    },
    showAddModal () {
      this.showModal = true
    },
    hideAddModal () {
      this.showModal = false
    },
    getPupilData () {
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('GET', 'users/me/school/groups/' + this.group.id + '/pupils', null)
        .then((response) => {
          const data = response.data
          this.pupilsData = data.pupils
          console.log('Data: ' + this.pupilData)
          if (!this.pupilsData || this.pupilsData.length === 0) {
            this.response = 'No Groups'
          }

          const pwPupils = []
          for (let i = 0; i < this.pupilsData.length; i++) {
            this.pupilsData[i].total_score = parseInt(this.pupilsData[i].total_score)
            this.pupilsData[i].total_score_number = parseInt(this.pupilsData[i].total_score_number)
            const p = this.passwordForUsername(this.pupilsData[i].username)
            if (p) {
              this.$set(this.pupilsData[i], 'password', p)
              pwPupils.push(this.pupilsData[i])
            }

            this.$emit('passwords-set', pwPupils)
          }
          this.searchDidChange()
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    forgetPasswords () {
      const c = confirm('Forget password on this computer? The password will still work for the pupil.')
      if (c) {
        for (let k = 0; k < this.pupilsData.length; k++) {
          if (window.localStorage) {
            window.localStorage.removeItem('password:' + this.pupilsData[k].username)
          }
          this.$delete(this.pupilsData[k], 'password')
        }
      }
    },
    removePupil (pupilId) {
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('DELETE', 'users/me/school/groups/' + this.group.id + '/pupils', { pupils: pupilId })
        .then((response) => {
          const data = response.data
          this.pupilsData = data.pupils

          // console.log('readerData: ' + this.readerData)
          if (!this.pupilsData || this.pupilsData.length === 0) {
            this.response = 'No Pupils'
          }
          this.searchDidChange()
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    didAddPupils (d) {
      console.log('d: ' + d)
      this.pupilsData = this.pupilsData.concat(d)
      for (let i = 0; i < this.pupilsData.length; i++) {
        this.pupilsData[i].selected = false
      }
      this.$emit('passwords-set', this.pupilsData)
      this.searchDidChange()
    },
    toggleLoading () {
      this.loading = (this.loading === '') ? 'loading' : ''
    }
  }
}
</script>

<style>
  tr.activitySpelling {
    background-color: #f8f9d4 !important;
  }
  tr.activityNumber {
    background-color: #ded4f9 !important;
  }
  .gamesTable {
    font-size: 0.8em;
  }

  .gamesTable td, .gamesTable th {
    text-align: center;
    padding: 2px 3px !important;
  }
</style>
