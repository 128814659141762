<template>
  <!-- Main content -->
  <div class="box box-warning">
    <div class="box-header with-border content">
      <h2 v-if="group">
        Teachers
      </h2>
      <div class="box-tools pull-right">
        <!-- Single button -->
        <div v-if="allTeachersData" class="btn-group">
          <button
            type="button"
            class="button dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            style="box-shadow: none; -webkit-box-shadow: none;"
          >
            <span class="icon"><i class="mdi mdi-plus" /></span> <span>Add Teacher</span>
          </button>
          <ul class="dropdown-menu">
            <li v-if="isAdmin">
              <router-link to="/teachers">
                + Add Teacher
              </router-link>
            </li>
            <li v-for="(teacher,index) in allTeachersData">
              <a href="#" @click.prevent="addTeacher(teacher.id)">{{ teacher.name }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="box-body">
      <b-table
        v-if="teachersData"
        :data.sync="teachersData"
        :paginated="true"
        :per-page="10"
        :mobile-cards="true"
        :striped="true"
        :selectable="false"
        :sort="false"
      >
        <template slot-scope="props">
          <b-table-column field="name" label="Name" item-width="10px">
            {{ props.row.name }}
          </b-table-column>
          <b-table-column field="actions" label="">
            <div class="btn-group is-pulled-right">
              <button class="button is-danger" @click.prevent="removeTeacher(props.row.id)">
                <span class="icon"><i class="mdi mdi-delete" /></span>
              </button>
              <!-- <router-link :to="'/users/'+props.row.username" class="btn button is-link">Details</router-link>
                    <button type="button" class="btn button is-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span class="caret"></span>
                      <span class="sr-only">Toggle Dropdown</span>
                    </button>
                    <ul class="dropdown-menu">
                      <li><a href="#" @click.prevent="removeTeacher(props.row.id)">Remove</a></li>
                    </ul> -->
            </div>
          </b-table-column>
        </template>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered content">
              <p>
                <b-icon
                  custom-class="far"
                  pack="fa"
                  icon="frown"
                  size="is-large"
                />
              </p>
              <p>Nothing here.</p>
            </div>
          </section>
        </template>
      </b-table>
      <div v-else class="alert">
        <b>{{ response }}</b>
      </div>
    </div>
  </div>
</template>

<script>
import { request } from '@/edshed-common/api'

export default {
  name: 'GroupTeachers',
  props: {
    group: null
  },
  data (router) {
    return {
      loading: '',
      teachersData: null,
      allTeachersData: null,
      response: 'Data Loading...'
    }
  },
  computed: {
    isAdmin () {
      return (this.$store.state.user.school.admin === 1)
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.getTeacherData()
      this.getAllTeachersData()
    })
  },
  methods: {
    getTeacherData () {
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('GET', 'users/me/school/groups/' + this.group.id + '/teachers', null)
        .then((response) => {
          const data = response.data
          this.teachersData = data.teachers

          // console.log('readerData: ' + this.readerData)
          if (!this.teachersData || this.teachersData.length === 0) {
            this.response = 'No Groups'
          }
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    getAllTeachersData () {
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('GET', 'users/me/school/teachers', null)
        .then((response) => {
          const data = response.data
          this.allTeachersData = data.teachers
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    removeTeacher (teacherId) {
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('DELETE', 'users/me/school/groups/' + this.group.id + '/teachers', { teachers: teacherId })
        .then((response) => {
          const data = response.data
          this.teachersData = data.teachers

          // console.log('readerData: ' + this.readerData)
          if (!this.teachersData || this.teachersData.length === 0) {
            this.response = 'No Teachers'
          }
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    addTeacher (teacherId) {
      const groupId = this.group.id

      request('PUT', 'users/me/school/groups/' + groupId + '/teachers', { teachers: teacherId })
        .then((response) => {
          const data = response.data
          this.teachersData = data.teachers

          // console.log('readerData: ' + this.readerData)
          if (!this.teachersData || this.teachersData.length === 0) {
            this.response = 'No Teachers'
          }

          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    toggleLoading () {
      this.loading = (this.loading === '') ? 'loading' : ''
    }
  }
}
</script>
<style scoped>
  .dropdown-menu {
    max-height: 260px;
    overflow-y: scroll;
  }
</style>
