<template>
  <div class="modal is-active">
    <div class="modal-background" />
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          Edit Group
        </p>
        <button class="delete" aria-label="close" @click.prevent="close" />
      </header>
      <section class="modal-card-body">
        <form>
          <div class="field">
            <label class="label">Name</label>
            <div class="control">
              <input v-model="groupName" class="input" type="text" placeholder="Group name">
            </div>
          </div>
          <div class="field">
            <label class="label">Include in leagues</label>
            <div class="control">
              <b-switch
                v-model="leagues"
                type="is-success"
              >
                {{ leagues ? 'Yes' : 'No' }}
              </b-switch>
            </div>
          </div>
        </form>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" @click.prevent="save">
          Save
        </button>
      </footer>
    </div>
  </div>
</template>
<script>
export default {
  name: 'EditGroup',
  props: ['name', 'disableLeagues'],
  data (router) {
    return {
      groupName: null,
      leagues: true
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.groupName = this.name
      this.leagues = !this.disableLeagues
    })
  },
  methods: {
    save () {
      this.$emit('save', this.groupName, this.leagues)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
<style scoped>
  .label {
    text-align: left;
  }
</style>
