<template>
  <div>
    <div class="">
      <div class="box box-primary">
        <div class="box-header with-border content">
          <h2>Competence <span class="tag is-dark">PREMIUM</span></h2>
        </div>
        <div v-if="$store.getters.isPremium || $store.getters.hasNumber" class="box-body">
          <h4>Combined correct answers (%) by date.</h4>
          <form class="form-inline text-right">
            <!-- <div class="radio form-group">
              <label for="typeSelector">List <input type="radio" value="list" name="type" v-model="chartType"></label>
            </div>
            <div class="radio form-group">
              <label for="typeSelector">Word <input type="radio" value="word" name="type" v-model="chartType"></label>
            </div> -->
            <div class="form-group">
              List <select v-if="chartType == 'list'" id="sourceList" v-model="selectedList" class="form-control" @change="renderChart">
                <option v-for="(ls, index) in allLists" :value="ls.ident">
                  {{ ls.title }}
                </option>
              </select>
              <select v-if="chartType == 'word'" id="sourceWord" v-model="selectedWord" class="form-control" @change="renderChart">
                <option v-for="(word, index) in allWords" :value="word">
                  {{ word }}
                </option>
              </select>
            </div>
            <div v-if="chartType == 'list'" class="form-group">
              Aggregate data <switches v-model="aggregate" theme="default" color="green" />
            </div>
          </form>
          <div id="chart1" class="ct-chart" />
        </div>
        <div v-else class="box-body text-center content">
          <h2>Premium Feature</h2>
          <p>Upgrade to premium to use this feature.</p>
          <router-link v-if="isAdmin" to="/manage-subscription" class="btn button is-link" data-dismiss="modal">
            Upgrade
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { request } from '@/edshed-common/api'
import $ from 'jquery'
import moment from 'moment'
import Chartist from 'chartist'
import VueChartist from 'v-chartist'
import Switches from 'vue-switches'
import _ from 'lodash'
require('chartist-plugin-legend')

export default {
  name: 'GroupCompetence',
  components: {
    VueChartist,
    Chartist,
    Switches
  },
  props: {
    group: null
  },
  data (router) {
    return {
      loading: '',
      historyData: null,
      chartType: 'list',
      allLists: [],
      allWords: [],
      selectedList: null,
      selectedWord: null,
      aggregate: true,
      response: 'Data Loading...',
      chart: null
    }
  },
  computed: {
    isAdmin () {
      return (this.$store.state.user.school.admin === 1)
    }
  },
  watch: {
    aggregate () {
      this.renderChart()
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.getHistoryData()
    })
  },
  methods: {
    renderChart () {
      const series = []

      if (this.chartType === 'list') {
        // now we need a series for each word
        // get all words in all plays in case of changes
        let words = []
        for (let i = 0; i < this.historyData.length; i++) {
          for (let j = 0; j < this.historyData[i].resultsData.length; j++) {
            if (this.historyData[i].list_ident === this.selectedList && this.historyData[i].resultsData[j].word && this.historyData[i].resultsData[j].word !== '') {
              words.push(this.historyData[i].resultsData[j].word)
              // console.log('list ident: ' + this.selectedList)
              // console.log(this.historyData[i].list_ident)
              // console.log('adding ' + this.historyData[i].resultsData[j].word)
            }
          }
        }
        words = _.uniq(words)
        // create a series for each word
        const aggSer = { name: 'List average', data: [] }
        for (let k = 0; k < words.length; k++) {
          const dat = this.getChartSeriesDataForWord(words[k])
          // console.log('dat: ' + JSON.stringify(dat))
          if (!this.aggregate) {
            const ser = { name: words[k], data: dat }
            series.push(ser)
          } else {
            let dta = aggSer.data
            dta = dta.concat(dat)
            aggSer.data = dta
          }
        }
        if (this.aggregate) {
          // var dates = []
          const newData = []
          for (let l = 0; l < aggSer.data.length; l++) {
            // dates.push(aggSer.dta.data[l].x)
            const x = moment(aggSer.data[l].x).format('YYYY/MM/DD')
            if (!newData[x]) {
              newData[x] = []
            }
            newData[x].push(aggSer.data[l].y)
          }
          let chartData = []
          for (const key in newData) {
            const d = { x: moment(key, 'YYYY/MM/DD').toDate(), y: newData[key].reduce((total, score) => total + score) / newData[key].length }
            chartData.push(d)
          }
          // console.log('aggser: ' + JSON.stringify(chartData))
          chartData = _.sortBy(chartData, ['x'])
          aggSer.data = chartData
          // console.log('aggser: ' + JSON.stringify(aggSer))
          series.push(aggSer)
        }
      } else { // word
        // one series
        const dt = this.getChartSeriesDataForWord(this.selectedWord)
        const s = { name: this.selectedWord, data: dt }
        series.push(s)
      }
      // this.chart = null

      this.chart = new Chartist.Line('#chart1', { series },
        {
          axisX: {
            type: Chartist.FixedScaleAxis,
            divisor: 5,
            labelInterpolationFnc (value) {
              return moment(value).format('MMM D')
            }
          },
          fullWidth: true,
          chartPadding: {
            right: 40
          },
          lineSmooth: Chartist.Interpolation.cardinal({
            tension: 0.1,
            fillHoles: true
          }),
          high: 100,
          low: 0,
          height: 300,
          plugins: [
            Chartist.plugins.legend()
          ]
        })
    },
    getChartSeriesDataForWord (word) {
      const d = []
      for (let i = 0; i < this.historyData.length; i++) {
        for (let j = 0; j < this.historyData[i].resultsData.length; j++) {
          if (this.historyData[i].resultsData[j].word.toLowerCase() === word.toLowerCase()) {
            const date = moment(this.historyData[i].timestamp).format('YYYY/MM/DD')
            const dp = { d: date, y: this.historyData[i].resultsData[j].correct }
            d.push(dp)
          }
        }
      }
      let dtar = []
      for (let k = 0; k < d.length; k++) {
        dtar.push(d[k].d)
      }
      dtar = _.uniq(dtar)

      const dt = []
      for (let l = 0; l < dtar.length; l++) {
        // get count of elements with key by filtering
        const df = _.filter(d, { d: dtar[l] })

        // average by reduce => sum & divide by length
        const len = df.length
        const sum = df.reduce(function (total, num) { return total + parseInt(num.y) }, 0)
        const avg = 100 * sum / len
        // create new el
        const el = { x: moment(dtar[l] + ' 00:00:00', 'YYYY/MM/DD HH:mm:ss').toDate(), y: avg }
        dt.push(el)
      }
      return dt
    },
    showChartFor (type, key) {
      this.chartType = type
      if (type === 'list') {
        this.selectedList = key
      } else {
        this.selectedWord = key
      }
      this.renderChart()
    },
    getHistoryData () {
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      const options = { type: this.chartType, ident: (this.chatType === 'list' ? this.selectedList : this.selectedWord), aggregate: this.aggregate }
      request('GET', 'users/me/school/groups/' + this.group + '/history', options)
        .then((response) => {
          const data = response.data
          this.historyData = data.data

          if (!this.historyData || this.historyData.length === 0) {
            this.response = 'No Groups'
          }

          for (let i = 0; i < this.historyData.length; i++) {
            const resArr = this.historyData[i].results.split(',')
            const resArr3 = []
            for (let j = 0; j < resArr.length; j++) {
              const resArr2 = resArr[j].split(':')
              const row = { word: resArr2[0], correct: resArr2[1], answerGiven: resArr2[2] }
              resArr3.push(row)
            }
            this.historyData[i].resultsData = resArr3
          }
          this.getAllLists()
          this.getAllWords()
          this.selectedList = this.allLists.length > 0 ? this.allLists[0].ident : null
          this.selectedWord = this.allWords[0]
          this.renderChart()
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
          // console.log('setting history: ' + this.historyData)
          // this.$emit('setHistoryData', this.historyData)
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response && error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    dateFromServerDate (dt) {
      if (dt === null || dt === '' || !moment(dt).isValid()) {
        return null
      }
      const m = moment(dt)
      // var utcOffset = new Date().getTimezoneOffset()
      // m.add({minutes: utcOffset})
      return m.format('DD/MM/YYYY HH:mm')
    },
    getAllLists () {
      const lists = []
      for (let i = 0; i < this.historyData.length; i++) {
        const l = { title: this.historyData[i].list_title, ident: this.historyData[i].list_ident }
        lists.push(l)
      }
      this.allLists = _.sortBy(_.uniqBy(lists, 'ident'), 'title')
    },
    getAllWords () {
      const words = []
      for (let i = 0; i < this.historyData.length; i++) {
        for (let j = 0; j < this.historyData[i].resultsData.length; j++) {
          if (this.historyData[i].resultsData[j].word && this.historyData[i].resultsData[j].word !== '') {
            words.push(this.historyData[i].resultsData[j].word)
          }
        }
      }
      this.allWords = _.orderBy(_.uniqBy(words), [word => word.toLowerCase()], ['asc'])
    },
    showGameDetail (gameId) {
      $('#gameDetail' + gameId).toggle()
    },
    toggleLoading () {
      this.loading = (this.loading === '') ? 'loading' : ''
    }
  }
}
</script>
